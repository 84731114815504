API_BASE = process.env.NEXT_PUBLIC_BASE
// API_BASE = 'https://newdolcediet.ga'
API_URL = process.env.NEXT_PUBLIC_CMS
TOKEN_EXPIRES = 7
RULE_PASSWORD = /^(?=.*\d)(?=.*[a-zA-Z])[?=.*\w~@#$%()^&*+=`|{}:;!.?\"'@%()\[\]-]{6,}$/
EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
PASS_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
FULLNAME_REGEX = /\s+\S*$/
PAYPAL_CLIENTID = 'ATsKug5TD1Tsj-wiSzy8vReLdgUoBVV6ACUFFeaVZkLImzDjX4L4yyuYvP-7rxOwXZ3EieX2zyi-5MvP'
BRAINTREE_SANDBOX_TOKEN = 'sandbox_d5qtnhyx_4jbxgwh3mjvzhyr4'
FB_APP_ID = '312218009169252'
GG_CAPTCHA_KEY = '6LdtyckZAAAAAOGQPLTUxsq9mv8nlqhJPrtHfA_0'
IG_TOKEN = 'IGQVJWS1d1QWdSMFVsMlN2MTB5bjB6OUhBd1BObXl3N09VblhrcFNUa2JXYkZAmS2NMcm5tV0JoanZAvNkhYMHBnUk5jVk0tTEJMRHdSM2NDRDVSc3NxZA0oxakszWDhuNnE2SHlVRmI0YndjeGRjN19rNwZDZD'
FOUR_WEEKS_MEMBERSHIP = '4wMembership'
TWELVE_WEEKS_MEMBERSHIP = '12wMembership'
TWELVE_MONTHS_MEMBERSHIP = '12mMembership'
TWELVE_MONTHS_MEMBERSHIP_ONE_MONTH = '12mMembership1m'
API_SIGNUP_SETTING = '/setting/signup'
API_PAYMENT_PROMOCODE = '/affiliate-codes/validate'
API_BRAINTREE_CHECKOUT = '/payments'
API_LOGIN = 'auth/local'
API_USER_ME = '/users/me'
API_REGISTER = 'auth/local/register'
API_ROLE = 'users-permissions/roles'
API_UPLOAD = '/upload'
API_FORGOT_PASSWORD = '/auth/forgot-password'
API_RESET_PASSWORD = '/auth/reset-password'
API_PROGRAMS = '/programs'
API_REGISTER_USER = '/platform/register'
API_TESTIMONIALS = '/testimonials'
API_NEWSLETTER = '/newsletter'
API_BLOG = API_BASE + '/blog/wp-json/wp/v2/posts'
API_CONTACT = '/contact'
BLOG_URL = API_BASE + '/blog'
FACEBOOK_LOGIN = process.env.NEXT_PUBLIC_FACEBOOK_LOGIN
GOOGLE_LOGIN = process.env.NEXT_PUBLIC_GOOGLE_LOGIN
APPLE_MERCHANT_IDENTIFIER = 'merchant.com.thedolcediet.applepay'
TRACKING_GA_ID = process.env.NEXT_PUBLIC_TRACKING_GA_ID
TRACKING_PIXEL_ID = process.env.NEXT_PUBLIC_TRACKING_PIXEL_ID
TRACKING_HOTJAR_ID = process.env.NEXT_PUBLIC_TRACKING_HOTJAR_ID
REDIRECT_MAP = {
  '/athletes': '/testimonials',
  '/returns-policy': '/privacy-policy',
  '/shipping-policy': '/privacy-policy',
  '/the-dolce-diet-personalized-my-diet-program': '/about-the-diet-plan'
}

CHOOSE_PROGRAM_VIEW_MODE = {
  PROGRAM: 'program',
  MEMBERSHIP: 'membership',
}

// Click magick
CMC_REF_4W = 'ATC3wts'
CMC_REF_12W = 'ATCllb'

CMC_REF_12W_SOLD = 'llb'
CMC_REF_4W_SOLD = '3wts'
CMC_REF_Trial_SOLD = 'trial'
CMC_REF_LL_SOLD = 'llb-discount'

CLICK_MAGICK_UID = process.env.NEXT_PUBLIC_CLICK_MAGICK_UID
CLICK_MAGICK_HID = process.env.NEXT_PUBLIC_CLICK_MAGICK_HID
CLICK_MAGICK_CMC_PROJECT = process.env.NEXT_PUBLIC_CLICK_MAGICK_CMC_PROJECT
